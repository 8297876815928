import { toNumber } from 'lodash'

export const parseObjectToArray = object => {
  const array = []
  if (object) {
    for (const [
        value,
        text,
      ] of Object.entries(object)) {
      array.push({
        value: toNumber(value) || value,
        text,
      })
    }
  }
  return array
}

<template>
  <ItemWrapper>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
        <v-row>
          <v-col cols="12">
            <AppCard :loading="pending.getTimeReportPresets" :title="$t('Create Time Report')" :prev-route="prevRoute">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <SearchSelectClient
                      v-model="client_id"
                      :loading="pending.getUnlinkedTasks"
                      :disabled="pending.getUnlinkedTasks"
                      rules="required"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VSelectWithValidation
                      v-model="tenant_id"
                      rules="required"
                      label="Tenant"
                      :items="tenantsArray"
                      menu-props="offset-y"
                      placeholder="Select Tenant"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="name" rules="required" label="Name" placeholder="Enter Name" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <DatePickerWithValidation
                      v-model="period"
                      label="Period"
                      name="period"
                      type="month"
                      rules="required"
                      placeholder="Enter Period"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="pending.createTimeReport"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </AppCard>
          </v-col>

          <v-col v-if="Object.keys(ticketsWithTasks).length >= 1" cols="12">
            <AppCard :title="$t('Unlinked Tasks')">
              <div class="text-center d-flex pb-4">
                <v-btn small @click="openAllPanels">Open all</v-btn>
                <v-btn small class="ml-2" @click="closeAllPanels">Close all</v-btn>
              </div>
              <v-expansion-panels v-model="ticketPanels" multiple>
                <v-expansion-panel v-for="(ticket, key) in ticketsWithTasks" :key="ticket.id">
                  <v-expansion-panel-header>
                    <p class="h4 my-2">
                      {{ key === 'withOutTicket' ? 'Not assigned tasks' : `Ticket: ${ticket.subject}` }}
                      <v-chip class="ml-5" small color="primary">Tasks: {{ ticket.tasks.length }}</v-chip>
                      <v-chip v-if="getCountSelectedTicketTasks(ticket)" class="ml-5" small color="success">
                        Selected Tasks: {{ getCountSelectedTicketTasks(ticket) }}
                      </v-chip>
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      v-model="time_tasks"
                      :headers="ticketTaskHeaders"
                      :items="ticket.tasks"
                      show-select
                      dense
                      item-key="id"
                      hide-default-footer
                    >
                      <template #[`item.from_date`]="{ item }">
                        <span>{{ $moment(item.from_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.to_date`]="{ item }">
                        <span>{{ $moment(item.to_date).format('DD.MM.YYYY HH:mm') }}</span>
                      </template>

                      <template #[`item.user`]="{ item }">
                        <span v-if="item.user">
                          {{ item.user.firstname }}
                          {{ item.user.lastname }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </AppCard>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'
import { parseObjectToArray } from '@/helpers'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation.vue'
import SearchSelectClient from '@/components/Clients/SearchSelectClient.vue'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation.vue'

export default defineComponent({
  name: 'CreateTimeReport',
  components: {
    AppCard,
    ItemWrapper,
    VTextFieldWithValidation,
    VSelectWithValidation,
    VAutocompleteWithValidation,
    SearchSelectClient,
    DatePickerWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/TimeReports')
    const period = ref(null)

    const ticketTaskHeaders = [
      {
        sortable: false,
        text: 'Message',
        value: 'message',
        width: 250,
      },
      {
        sortable: false,
        text: 'From',
        value: 'from_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'To',
        value: 'to_date',
        width: 160,
      },
      {
        sortable: false,
        text: 'User',
        value: 'user',
      },
    ]

    // store
    const pending = computed(_ => $store.state.timeReport.pending)
    const timeReportPresets = computed(_ => $store.state.timeReport.timeReportPresets)
    const ticketsWithTasks = computed(_ => $store.getters['timeReport/ticketsWithTasks'])
    const errors = computed(_ => $store.state.timeReport.errors)
    const createTimeReport = _ => $store.dispatch('timeReport/createTimeReport')
    const getTimeReportPresets = _ => $store.dispatch('timeReport/getTimeReportPresets')
    const getUnlinkedTasks = clientId => $store.dispatch('timeReport/getUnlinkedTasks', clientId)
    const clearTimeReport = _ => $store.commit('timeReport/CLEAR_TIME_REPORT')

    const tenantsArray = computed(_ => parseObjectToArray(timeReportPresets.value.tenants))

    getTimeReportPresets()

    const timeReportMapFields = mapFields('timeReport', [
      'timeReport.name',
      'timeReport.client_id',
      'timeReport.tenant_id',
      'timeReport.year',
      'timeReport.month',
      'timeReport.time_tasks',
    ])

    watch(period, date => {
      const yearMonthRegex = /^(\d{4})-(0[1-9]|1[0-2])$/
      const periodMatch = date.match(yearMonthRegex)
      timeReportMapFields.year.value = periodMatch ? periodMatch[1] : null
      timeReportMapFields.month.value = periodMatch ? periodMatch[2] : null
    })

    watch(timeReportMapFields.client_id, async clientId => {
      await getUnlinkedTasks(clientId)
      openAllPanels()
    })

    const getCountSelectedTicketTasks = ticket => {
      return ticket.tasks.filter(task => timeReportMapFields.time_tasks.value.includes(task.id)).length
    }

    const submitForm = _ => {
      if (pending.value.createTimeReport) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createTimeReport().then(({ timeReport, errors }) => {
        if (errors) {
          setForceRedirect(false)
          return
        }

        if (redirectDirect.value) {
          $router.push({
            name: 'timeReports-item',
            params: { id: timeReport.id },
          })
        } else {
          $router.push({ path: '/TimeReports' })
        }
      })
    }

    const ticketPanels = ref([])

    const openAllPanels = _ => {
      ticketPanels.value = Object.keys(ticketsWithTasks.value).map((k, i) => i)
    }

    const closeAllPanels = _ => {
      ticketPanels.value = []
    }

    onUnmounted(_ => {
      clearTimeReport()
    })

    return {
      prevRoute,
      pending,
      period,
      ticketPanels,
      ticketsWithTasks,
      ticketTaskHeaders,
      getCountSelectedTicketTasks,
      tenantsArray,
      errors,
      submitForm,
      openAllPanels,
      closeAllPanels,
      ...timeReportMapFields,
    }
  },
}) //
</script>
